import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _620102da = () => interopDefault(import('../pages/AAHT.vue' /* webpackChunkName: "pages/AAHT" */))
const _e770f44c = () => interopDefault(import('../pages/ABBM.vue' /* webpackChunkName: "pages/ABBM" */))
const _747b1ce6 = () => interopDefault(import('../pages/caregiving.vue' /* webpackChunkName: "pages/caregiving" */))
const _20f24c60 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _46ca4411 = () => interopDefault(import('../pages/demo.vue' /* webpackChunkName: "pages/demo" */))
const _9169a55a = () => interopDefault(import('../pages/lmr.vue' /* webpackChunkName: "pages/lmr" */))
const _ea6c4a5e = () => interopDefault(import('../pages/patient-insights.vue' /* webpackChunkName: "pages/patient-insights" */))
const _5f4c88ab = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _53a56a31 = () => interopDefault(import('../pages/sign-on.vue' /* webpackChunkName: "pages/sign-on" */))
const _69c022e3 = () => interopDefault(import('../pages/stm-school.vue' /* webpackChunkName: "pages/stm-school" */))
const _5d76151a = () => interopDefault(import('../pages/style.vue' /* webpackChunkName: "pages/style" */))
const _4fe47180 = () => interopDefault(import('../pages/theinfusion-referral-process.vue' /* webpackChunkName: "pages/theinfusion-referral-process" */))
const _197eeb07 = () => interopDefault(import('../pages/travel-to-learn.vue' /* webpackChunkName: "pages/travel-to-learn" */))
const _7e7a894d = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _27f95543 = () => interopDefault(import('../pages/williamsburg-aaht.vue' /* webpackChunkName: "pages/williamsburg-aaht" */))
const _4c06e0d4 = () => interopDefault(import('../pages/ymcarichmond.vue' /* webpackChunkName: "pages/ymcarichmond" */))
const _e6a7bba2 = () => interopDefault(import('../pages/companies/add.vue' /* webpackChunkName: "pages/companies/add" */))
const _9f22158c = () => interopDefault(import('../pages/projects/add.vue' /* webpackChunkName: "pages/projects/add" */))
const _35888814 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9f485dc0 = () => interopDefault(import('../pages/companies/_company.vue' /* webpackChunkName: "pages/companies/_company" */))
const _18c8e354 = () => interopDefault(import('../pages/projects/_project/index.vue' /* webpackChunkName: "pages/projects/_project/index" */))
const _d6e8a030 = () => interopDefault(import('../pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _08d72fc1 = () => interopDefault(import('../pages/users/_user/sign-up.vue' /* webpackChunkName: "pages/users/_user/sign-up" */))
const _600b4c85 = () => interopDefault(import('../pages/projects/_project/participants/_participant.vue' /* webpackChunkName: "pages/projects/_project/participants/_participant" */))
const _4369d920 = () => interopDefault(import('../pages/projects/_project/stories/_story.vue' /* webpackChunkName: "pages/projects/_project/stories/_story" */))
const _7fb4de92 = () => interopDefault(import('../pages/users/_user/_project/index.vue' /* webpackChunkName: "pages/users/_user/_project/index" */))
const _42139165 = () => interopDefault(import('../pages/users/_user/_project/journey.vue' /* webpackChunkName: "pages/users/_user/_project/journey" */))
const _6791244f = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _862ec206 = () => interopDefault(import('../pages/_slug/journey.vue' /* webpackChunkName: "pages/_slug/journey" */))
const _25f7e3ac = () => interopDefault(import('../pages/_slug/sign-on.vue' /* webpackChunkName: "pages/_slug/sign-on" */))
const _30512668 = () => interopDefault(import('../pages/_slug/sign-up.vue' /* webpackChunkName: "pages/_slug/sign-up" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AAHT",
    component: _620102da,
    name: "AAHT"
  }, {
    path: "/ABBM",
    component: _e770f44c,
    name: "ABBM"
  }, {
    path: "/caregiving",
    component: _747b1ce6,
    name: "caregiving"
  }, {
    path: "/companies",
    component: _20f24c60,
    name: "companies"
  }, {
    path: "/demo",
    component: _46ca4411,
    name: "demo"
  }, {
    path: "/lmr",
    component: _9169a55a,
    name: "lmr"
  }, {
    path: "/patient-insights",
    component: _ea6c4a5e,
    name: "patient-insights"
  }, {
    path: "/projects",
    component: _5f4c88ab,
    name: "projects"
  }, {
    path: "/sign-on",
    component: _53a56a31,
    name: "sign-on"
  }, {
    path: "/stm-school",
    component: _69c022e3,
    name: "stm-school"
  }, {
    path: "/style",
    component: _5d76151a,
    name: "style"
  }, {
    path: "/theinfusion-referral-process",
    component: _4fe47180,
    name: "theinfusion-referral-process"
  }, {
    path: "/travel-to-learn",
    component: _197eeb07,
    name: "travel-to-learn"
  }, {
    path: "/users",
    component: _7e7a894d,
    name: "users"
  }, {
    path: "/williamsburg-aaht",
    component: _27f95543,
    name: "williamsburg-aaht"
  }, {
    path: "/ymcarichmond",
    component: _4c06e0d4,
    name: "ymcarichmond"
  }, {
    path: "/companies/add",
    component: _e6a7bba2,
    name: "companies-add"
  }, {
    path: "/projects/add",
    component: _9f22158c,
    name: "projects-add"
  }, {
    path: "/",
    component: _35888814,
    name: "index"
  }, {
    path: "/companies/:company",
    component: _9f485dc0,
    name: "companies-company"
  }, {
    path: "/projects/:project",
    component: _18c8e354,
    name: "projects-project"
  }, {
    path: "/users/:user",
    component: _d6e8a030,
    name: "users-user"
  }, {
    path: "/users/:user/sign-up",
    component: _08d72fc1,
    name: "users-user-sign-up"
  }, {
    path: "/projects/:project/participants/:participant?",
    component: _600b4c85,
    name: "projects-project-participants-participant"
  }, {
    path: "/projects/:project/stories/:story?",
    component: _4369d920,
    name: "projects-project-stories-story"
  }, {
    path: "/users/:user/:project",
    component: _7fb4de92,
    name: "users-user-project"
  }, {
    path: "/users/:user/:project/journey",
    component: _42139165,
    name: "users-user-project-journey"
  }, {
    path: "/:slug",
    component: _6791244f,
    name: "slug"
  }, {
    path: "/:slug/journey",
    component: _862ec206,
    name: "slug-journey"
  }, {
    path: "/:slug/sign-on",
    component: _25f7e3ac,
    name: "slug-sign-on"
  }, {
    path: "/:slug/sign-up",
    component: _30512668,
    name: "slug-sign-up"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
