import { render, staticRenderFns } from "./authorize.vue?vue&type=template&id=b9f8def4"
import script from "./authorize.vue?vue&type=script&lang=js"
export * from "./authorize.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Snackbar: require('/Users/scott/GitHub/sms/seeq-app/components/Snackbar.vue').default})
